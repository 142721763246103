import { SISECASettings } from '@/types/SIS/SISECASettings'
import { SISSettings } from '@/types/SIS/SISSettings'
import { useFrappeGetCall } from 'frappe-react-sdk'
import React, { createContext } from 'react'

interface SettingsContextProps {
  allowOverBudget?: 0 | 1
  allowRegistration?: 0 | 1
  tailorMadePrice?: number
  overBudgetDiscount?: number
}

type SISSettingsExtend = SISSettings & {
  eca_settings: SISECASettings
}

export const SettingsContext = createContext<SISSettingsExtend>({} as SISSettingsExtend)

export const SettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data } = useFrappeGetCall<{ message: SISSettingsExtend }>(
    'parent_portal.api.settings.get_settings',
  )

  return (
    <SettingsContext.Provider value={data?.message || ({} as SISSettingsExtend)}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsContext = () => React.useContext(SettingsContext)
