import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom'
import { AUTH_ROUTES } from './auth/auth.route'
import { FC, useMemo } from 'react'
import { PARENT_ROUTES } from './parent/parent.router'
import { TEACHER_ROUTES } from './teacher/teacher.router'
import { ADMIN_ROUTES } from './admin/admin.router'
import { useAuthContext } from '@/lib/auth/useAuth'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import env from '@/config/env'
import * as Sentry from '@sentry/react'
import ClientErrorState from '@features/states/client-error-state'
import HomeRedirect from '@/lib/auth/permissions/home-redirect'

export type GlobalErrorProps = {
  className?: string
}

export const GlobalError: FC<GlobalErrorProps> = () => {
  const error = useRouteError()

  if (import.meta.env.MODE !== 'development' && error) {
    console.log(error)
    Sentry.captureException(error)
  }
  console.log(error)

  return <ClientErrorState />
}

const RootRouter = () => {
  const { currentUser, isLoading, userRole } = useAuthContext()

  const router = useMemo(
    () =>
      createBrowserRouter(
        [
          {
            path: '/',
            errorElement: <GlobalError />,
            lazy: () => import('./layout'),
            children: [
              {
                index: true, // This handles the exact `/` path
                element: <HomeRedirect />,
              },
              ...AUTH_ROUTES,
              ...PARENT_ROUTES,
              ...TEACHER_ROUTES,
              ...ADMIN_ROUTES,

              // Catch-All for Unauthorized Access
              {
                path: '*',
                element: <HomeRedirect />,
              },
            ],
          },
        ],
        {
          basename: `/${env.BASE_NAME}`,
        },
      ),
    [userRole],
  )
  if (isLoading) return <FullPageLoaderTemplate />
  return <RouterProvider key={currentUser} router={router} />
}

export default RootRouter
