import {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
  type FC,
} from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import { useLocales } from '@/core/hooks'
import moment from 'moment'
import { REGEX } from '@/core/constant/regex'
import { format } from 'date-fns'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@atoms/form'
import AlertStatus from '@molecules/alert-status'
import { InputGroup } from '@molecules/input-group'
import { CalendarIcon, Check, CircleAlert } from 'lucide-react'
import { Input } from '@atoms/input'
import {
  SelectDistricts,
  SelectProvinces,
  SelectWards,
} from '@molecules/select-provinces'
import DateTimePicker from '@molecules/date-time-picker'
import { SelectContries } from '@molecules/select-countries'
import { useAuthContext } from '@/lib/auth/useAuth'
import { useFormUpdateProfile } from './hooks/use-form-update-profile'

export type UpdateProfileFormProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren & {
    email?: string
    onSubmit?: (data: any) => Promise<void>
  }

export const UpdateProfileForm: FC<UpdateProfileFormProps> = ({
  children,
  className,
  onSubmit,
}) => {
  const { t } = useLocales()
  const { currentUser, userInfo } = useAuthContext()
  const { form } = useFormUpdateProfile()
  const [birthday, setBirthday] = useState<string>('')

  const handleSubmit = form.handleSubmit(async (data) => {
    onSubmit?.({
      ...data,
      date_of_birth: data.date_of_birth && format(data.date_of_birth, 'yyyy-MM-dd'),
      province: data.province ? JSON.parse(data.province).name : null,
      province_code: data.province ? JSON.parse(data.province).code : null,
      district: data.district ? JSON.parse(data.district).name : null,
      district_code: data.district ? JSON.parse(data.district).code : null,
      ward: data.ward ? JSON.parse(data.ward).name : null,
      ward_code: data.ward ? JSON.parse(data.ward).code : null,
    })
  })

  const handleChangeBirthday = (str_date: string) => {
    let current_date = form.watch('date_of_birth')
    const reg = new RegExp(REGEX.date)
    if (reg.test(str_date)) {
      try {
        let birth_date = moment(str_date, 'DD/MM/YYYY').toDate()
        return birth_date
      } catch (err) {
        return current_date
      }
    }
    return current_date
  }

  useEffect(() => {
    if (userInfo) {
      form.reset((prev: any) => ({
        ...prev,
        last_name: userInfo.last_name,
        first_name: userInfo.first_name,
        date_of_birth: userInfo.date_of_birth
          ? new Date(userInfo.date_of_birth)
          : new Date(),
        phone_number: userInfo.phone_number?.replaceAll(' ', ''),
        street: userInfo.street || '',
        province: JSON.stringify({
          name: userInfo.province,
          code: Number(userInfo.province_code),
        }),
        district: JSON.stringify({
          name: userInfo.district,
          code: Number(userInfo.district_code),
        }),
        ward: JSON.stringify({
          name: userInfo.ward,
          code: Number(userInfo.ward_code),
        }),
        email: currentUser,
        company: userInfo.company || '',
        job_title: userInfo.job_title || '',
        nationality: userInfo.nationality || '',
      }))
    }
  }, [userInfo])

  useEffect(() => {
    setTimeout(() => {
      let birth_date = form.watch('date_of_birth')
      birth_date && setBirthday(format(birth_date, 'dd/MM/yyyy'))
    }, 200)
  }, [form.watch('date_of_birth')])

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit}
      >
        <div className="h-full pb-5 pr-2">
          <div className="flex flex-col gap-5">
            <div className="group-2">
              <h4 className="mb-2 font-bold text-primary">
                {t('common.contact_information')}
              </h4>
              <div className="grid gap-5 sm:grid-cols-2">
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.email.label')}
                    <span className="text-xs text-brand-red">*</span>
                  </label>
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <InputGroup
                            inputClassName="pr-12"
                            {...field}
                            readOnly
                            placeholder={t(
                              'components.inputs.email.placeholder',
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.phone.label')}{' '}
                    <span className="text-xs text-brand-red">*</span>
                  </label>
                  <FormField
                    control={form.control}
                    name="phone_number"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              'components.inputs.phone.placeholder',
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1 sm:col-span-2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.address.label')}
                  </label>
                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="street"
                      render={({ field, formState: { isSubmitting } }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t(
                                'components.inputs.address.placeholder',
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-3">
                      <FormField
                        control={form.control}
                        name="province"
                        render={({ field, formState: { isSubmitting } }) => (
                          <FormItem>
                            <FormControl>
                              <SelectProvinces
                                className="w-full"
                                value={field.value?.toString()}
                                onSelect={(value) => {
                                  field.onChange(value)
                                  form.setValue('district', undefined)
                                  form.setValue('ward', undefined)
                                }}
                                placeholder={t(
                                  'components.inputs.province.placeholder',
                                )}
                              />
                            </FormControl>
                            {/* <FormMessage /> */}
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="district"
                        render={({ field, formState: { isSubmitting } }) => (
                          <FormItem>
                            <FormControl>
                              <SelectDistricts
                                className="w-full"
                                provinceCode={
                                  JSON.parse(form.watch('province') || '{}')
                                    ?.code
                                }
                                value={field.value?.toString()}
                                onSelect={(value) => {
                                  field.onChange(value)
                                  form.setValue('ward', undefined)
                                }}
                                placeholder={t(
                                  'components.inputs.district.placeholder',
                                )}
                              />
                            </FormControl>
                            {/* <FormMessage /> */}
                          </FormItem>
                        )}
                      />
                      {/* <SelectDistricts province={1} /> */}
                      <FormField
                        control={form.control}
                        name="ward"
                        render={({ field, formState: { isSubmitting } }) => (
                          <FormItem>
                            <FormControl>
                              <SelectWards
                                className="w-full"
                                district={
                                  JSON.parse(form.watch('district') || '{}')
                                    ?.code
                                }
                                value={field.value?.toString()}
                                onSelect={field.onChange}
                                placeholder={t(
                                  'components.inputs.ward.placeholder',
                                )}
                              />
                            </FormControl>
                            {/* <FormMessage /> */}
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.company_name.label')}
                  </label>
                  <FormField
                    control={form.control}
                    name="company"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              'components.inputs.company_name.placeholder',
                            )}
                          />
                        </FormControl>
                        {/* <FormMessage /> */}
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.job_title.label')}
                  </label>
                  <FormField
                    control={form.control}
                    name="job_title"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              'components.inputs.job_title.placeholder',
                            )}
                          />
                        </FormControl>
                        {/* <FormMessage /> */}
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="group-1">
              <h4 className="mb-2 font-bold text-primary">
                {t('common.personal_information')}
              </h4>
              <div className="grid gap-5 sm:grid-cols-2">
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.last_name.label')}
                    <span className="text-xs text-brand-red">*</span>
                  </label>
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              'components.inputs.last_name.placeholder',
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.name.label')}
                    <span className="text-xs text-brand-red">*</span>
                  </label>
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              'components.inputs.name.placeholder',
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.date_of_birth.label')}
                  </label>
                  <FormField
                    control={form.control}
                    name="date_of_birth"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <InputGroup
                            // variant={'outline'}
                            inputClassName={cn(
                              'w-full justify-between text-left font-normal',
                            )}
                            onChange={(e) => {
                              setBirthday(e.target.value)
                              field.onChange(
                                handleChangeBirthday(e.target.value),
                              )
                            }}
                            onBlur={(e) => {
                              const curDate = form.watch('date_of_birth')
                              if (curDate) {
                                setBirthday(format(curDate, 'dd/MM/yyyy'))
                              }
                            }}
                            suffix={
                              <DateTimePicker
                                value={field.value}
                                onChange={(d) =>
                                  field.onChange(
                                    handleChangeBirthday(
                                      format(d, 'dd/MM/yyyy'),
                                    ),
                                  )
                                }
                                contentProps={{ align: 'end' }}
                                disabled={{
                                  after: new Date(),
                                }}
                              >
                                <CalendarIcon className="mr-4 h-4 w-4 cursor-pointer" />
                              </DateTimePicker>
                            }
                            placeholder={'MM/DD/YYYY'}
                            value={birthday}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-muted-foreground"
                  >
                    {t('components.inputs.national.label')}
                  </label>
                  <FormField
                    control={form.control}
                    name="nationality"
                    render={({ field, formState: { isSubmitting } }) => (
                      <FormItem>
                        <FormControl>
                          <SelectContries
                            className="w-full"
                            placeholder={t(
                              'components.inputs.national.placeholder',
                            )}
                            value={field.value}
                            onSelect={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </form>
    </Form>
  )
}
