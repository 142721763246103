import { useGetSchoolClassesByUser } from '@/api/schoolClass/use-get-school-classes-by-user'
import { SchoolClassSimpleExtend } from '@/types/Extends/SchoolClass'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../auth/useAuth'

interface AdminContextProps {
  schoolClasses?: SchoolClassSimpleExtend[]
}

export const AdminContext = createContext<AdminContextProps>({
  schoolClasses: undefined,
})

export const useAdmin = () => {
  const context = React.useContext(AdminContext)
  if (!context) {
    throw new Error('useChildren must be used within a AdminProvider')
  }
  return context
}

export const AdminProvider = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = useAuthContext()
  const {
    data: schoolClasses,
    isLoading: schoolClassLoading,
    error: errSchoolClasses,
  } = useGetSchoolClassesByUser(!currentUser)
  return (
    <>
      {useMemo(
        () => (
          <AdminContext.Provider
            value={{
              schoolClasses: schoolClasses,
            }}
          >
            {children}
          </AdminContext.Provider>
        ),
        [JSON.stringify(schoolClasses)],
      )}
    </>
  )
}
