import { SISPerson } from '@/types/SIS/SISPerson'

export enum SISRoleType {
  PP_USER = 'PP User',
  PP_DESK = 'PP Desk',
  SIS_ATTENDANCE_MANAGER = 'SIS Attendance Manager',
  SYSTEM_MANAGER = 'System Manager',
  SIS_ADMISSION_MANAGER = 'SIS Admission Manager',
  SIS_REPORT_CARD_MANAGER = 'SIS Student Report Card Manager',
  SIS_CONTENT_CREATOR = 'SIS Content Creator',
  SIS_ECA_FORM_ADMIN = 'SIS ECA Form Admin',
  ALL = 'All',
  GUEST = 'Guest',
}

export enum USER_ROLE {
  GUARDIAN = 'Parent',
  TEACHER = 'Teacher',
  ADMIN = 'Admin',
  ALL = 'All',
}

export type AuthContextProps = {
  currentUser: string | null
  userInfo: SISPerson | null
  userRole: USER_ROLE | null
  userSISRoles: SISRoleType[]
  isLoading: boolean
  isRequesting: boolean
  isAgreedTerms?: boolean
  prefixRoute: string | null
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
  updateCurrentUser: VoidFunction
  updateUserInfo: VoidFunction
  resetPassword: (email: string, send_email: boolean) => void
  updatePassword: (key: string, newPassword: string) => void
  changePassword: (oldPassword: string, newPassword: string) => void
  acceptTerms: () => Promise<void>
  isLoggingOut: boolean
  setIsLoggingOut: (value: boolean) => void
}
